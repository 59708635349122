<template>
	<section class="grey lighten-3 pa-3">
		<h3>Mesa de Contacto (Logos)</h3>
		<v-row no-gutters>
			<v-col cols="12" sm="7">
				<v-text-field
					class="mx-1"
					dense
					solo
					label="Imagen"
					v-model="imagen"
				/>
			</v-col>
			<v-col cols="12" sm="4">
				<v-text-field
					class="mx-1"
					dense
					solo
					label="Link"
					v-model="link"
				/>
			</v-col>
			<v-col cols="12" sm="1">
				<v-btn class="mx-1 success" text @click.prevent="add"><v-icon dark>mdi-plus</v-icon></v-btn>
			</v-col>
		</v-row>

		<div class="py-1">
			<v-divider />
		</div>
		<v-simple-table v-if="sponsors.length>=1">
			<template v-slot:default>
				<thead>
					<tr class="grey darken-2">
						<th class="text-center white--text">
							ID
						</th>
						<th class="text-center white--text">
							Imagen
						</th>
						<th width="10%">
							&nbsp;
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(p,i) in sponsors" :key="i" class="my-3 grey lighten-5">
						<td class="text-center">{{p.id}}</td>
						<td class="text-left"><span class="ma-1"><img :src="p.imagen" height="50" /></span><br/>
							<b>	img:</b> {{sponsors[i].imagen}} <br/>
							<b>link:</b> {{sponsors[i].link}}
							<!-- <v-text-field
								class="mx-1"
								dense
								solo
								v-model="sponsors[i].imagen"
							/>
							<v-text-field
								class="mx-1"
								dense
								solo
								v-model="sponsors[i].link"
							/> -->
						</td>
						<td class="text-center"><v-btn class="mx-1" x-small text @click.prevent="remove(i)"><v-icon class="red--text">mdi-trash-can-outline</v-icon></v-btn></td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>



	</section>
</template>

<script>
	export default {
		name: 'SponsorsComponent',
		props: ['sponsors'],
		data: () => ({
			imagen: '',
			link: '',
		}),
		methods: {
			add(){
				let i = this.sponsors.length+1
				this.sponsors.push({
					id: i,
					imagen: this.imagen,
					link: this.link
				})

				this.$emit('emitSponsors', this.sponsors)

				this.imagen = ''
				this.link = ''
			},
			remove(i){
				this.sponsors.splice(i,1)
				let tempArray = []
				for (let index = 0; index < this.sponsors.length; index++) {
					tempArray.push({
						id: index+1,
						imagen: this.sponsors[index].imagen,
						link: this.sponsors[index].link,
					})
				}
				this.$emit('emitSponsors', tempArray)
			},
		},
		computed: {

		}
	}
</script>

<style lang="scss" scoped>

</style>