<template>
	<section class="grey lighten-3 pa-3">
		<h3>PRECIOS</h3>
		<v-row no-gutters>
			<!-- <v-col cols="12" sm="1">
				<v-text-field
					class="mx-1"
					dense
					solo
					v-model="orden"
				/>
			</v-col> -->
			<v-col cols="12" sm="8">
				<v-text-field
					class="mx-1"
					dense
					solo
					label="Descripción"
					v-model="descripcion"
				/>
			</v-col>
			<v-col cols="12" sm="2">
				<v-text-field
					class="mx-1"
					dense
					solo
					label="Precio"
					v-model="precio"
				/>
			</v-col>
			<v-col cols="12" sm="1">
				<v-btn class="mx-1 success" text @click.prevent="add"><v-icon dark>mdi-plus</v-icon></v-btn>
			</v-col>
		</v-row>

		<div class="py-1">
			<v-divider />
		</div>
		<v-simple-table v-if="precios.length>=1">
			<template v-slot:default>
				<thead>
					<tr class="grey darken-2">
						<th class="text-center white--text">
							ORDEN
						</th>
						<th class="text-center white--text">
							DESCRIPCIÓN
						</th>
						<th class="text-center white--text">
							Value
						</th>
						<th class="text-center white--text">
							PRECIO
						</th>
						<th width="10%">
							&nbsp;
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(p,i) in precios" :key="i">
						<td class="text-center">{{p.orden}}</td>
						<td class="text-left">{{p.descripcion}}</td>
						<td class="text-left">{{p.precio}}</td>
						<td class="text-center"><v-btn class="mx-1" x-small text @click.prevent="remove(i)"><v-icon class="red--text">mdi-trash-can-outline</v-icon></v-btn></td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>



	</section>
</template>

<script>
	export default {
		name: 'PreciosComponent',
		props: ['precios'],
		data: () => ({
			descripcion: '',
			precio: '',
		}),
		methods: {
			add(){
				let i = this.precios.length+1
				this.precios.push({
					orden: i,
					descripcion: this.descripcion,
					precio: this.precio
				})

				this.$emit('emitPrecios', this.precios)

				this.descripcion = ''
				this.precio = ''
			},
			remove(i){
				this.precios.splice(i,1)
				let tempArray = []
				for (let index = 0; index < this.precios.length; index++) {
					tempArray.push({
						orden: index+1,
						descripcion: this.precios[index].descripcion,
						precio: this.precios[index].precio,
					})
				}
				this.$emit('emitPrecios', tempArray)
			}

		},
		computed: {

		}
	}
</script>

<style lang="scss" scoped>

</style>