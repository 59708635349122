<template>
	<Layout>
		<v-container>
			<v-form ref="form" v-model="formValidity">
				<v-row class="justify-center">
					<v-col cols="12">
						<h2> <v-icon large>mdi-calendar</v-icon> NUEVO EVENTO</h2>
					</v-col>
					<v-col cols="12" sm="10">
						<v-text-field
							label="Título"
							v-model="evento.titulo"
							:rules="[rules.required]"
						/>

						<v-row>
							<v-col cols="12" sm="4">
								<v-text-field
									type="date"
									label="Fecha Inicio"
									v-model="evento.fechaInicio"
								/>
							</v-col>
							<v-col cols="12" sm="4">
								<v-text-field
									type="date"
									label="Fecha Final"
									v-model="evento.fechaFin"
								/>
							</v-col>
							<v-col cols="12" sm="4">
								<v-text-field
									label="Slug"
									v-model="evento.slug"
									:rules="[rules.required]"
								/>
							</v-col>
						</v-row>

						<v-text-field
							label="Subtítulo (Fecha y sitio)"
							v-model="evento.subtitulo"
							:rules="[rules.required]"
						/>
						<!-- <h4>Subtítulo</h4>
						<tiptap-vuetify
							v-model="evento.subtitulo"
							:extensions="extensions"
						/> -->
						<h4>Información (Opcional)</h4>
						<tiptap-vuetify
							v-model="evento.informacion"
							:extensions="extensions"
							min-height="4"
						/>

						<div class="d-flex flex-row justify-space-between">
							<v-switch
								inset class="ma-2"
								color="success"
								v-model="evento.presencial"
								:label="`¿Solo Presencial?: ${evento.presencial ? 'SI' : 'NO'}`"
							></v-switch>
							<v-switch
								inset class="ma-2"
								color="success"
								v-model="evento.gratis"
								:label="`¿Evento gratis?: ${evento.gratis ? 'SI' : 'NO'}`"
							></v-switch>

						</div>

						<section v-if="!evento.gratis">
							<precios-component :precios="precios" @emitPrecios="setPrecios" />
						</section>

						<v-text-field
							label="URL Afiche"
							v-model="evento.afiche"
							:rules="[rules.required]"
						/>
						<v-text-field
							label="URL Programa"
							v-model="evento.programa"
							:rules="[rules.required]"
						/>
						<v-text-field
							label="URL Librito"
							v-model="evento.librito"
						/>
						<v-text-field
							label="URL zoom Público"
							v-model="evento.webinar"
						/>
						<v-text-field
							label="URL zoom Privado"
							v-model="evento.meeting"
						/>
						<section class="grey lighten-3 pa-3">
							<h4>Banner Detalle Header</h4>
							<v-text-field
								label="Imagen"
								v-model="evento.bannerDetalleHeader.image"
							/>
							<v-text-field
								label="Link"
								v-model="evento.bannerDetalleHeader.link"
							/>
						</section>
						<section class="grey lighten-3 pa-3 my-3">
							<h4>Banner Inscripción Header</h4>
							<v-text-field
								label="Imagen"
								v-model="evento.bannerInscripcionHeader.image"
							/>
							<v-text-field
								label="Link"
								v-model="evento.bannerInscripcionHeader.link"
							/>
						</section>
						<section class="grey lighten-3 pa-3 my-3">
							<h4>Banner Inscripción Email Header</h4>
							<v-text-field
								label="Imagen"
								v-model="evento.bannerInscripcionEmail.image"
							/>
							<v-text-field
								label="Link"
								v-model="evento.bannerInscripcionEmail.link"
							/>
						</section>
						<section class="grey lighten-3 pa-3 my-3">
							<h4>Banner Transmisión Header</h4>
							<v-text-field
								label="Imagen"
								v-model="evento.bannerTransmisionHeader.image"
							/>
							<v-text-field
								label="Link"
								v-model="evento.bannerTransmisionHeader.link"
							/>
						</section>
						<section class="grey lighten-3 pa-3 my-3">
							<h4>Banner Transmisión Footer</h4>
							<v-text-field
								label="Imagen"
								v-model="evento.bannerTransmisionFooter.image"
							/>
							<v-text-field
								label="Link"
								v-model="evento.bannerTransmisionFooter.link"
							/>
						</section>
						<sponsors-component :sponsors="sponsors" @emitSponsors="setSponsors" />

					</v-col>

					<v-col cols="12">
						<div class="text-center my-5">
							<v-btn
								@click="goEventoList()"
								x-large
								text
								color="info accent-3"
							>
								Cancelar
							</v-btn>
							<v-btn
								x-large
								@click.stop="storeEvento()"
								:loading="saving"
								:disabled="!formValidity"
								rounded
								color="primary accent-3">
								CREAR EVENTO
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-form>
		</v-container>

	</Layout>
</template>

<script>
	import { mapState } from 'vuex';
	import axios from 'axios';
	import store from '@/store'
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	import { TiptapVuetify, HardBreak , Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History } from 'tiptap-vuetify'
	import PreciosComponent from '@/components/eventos/PreciosComponent.vue'
	import SponsorsComponent from '@/components/eventos/SponsorsComponent.vue'

	export default {
		name: 'EventoNew',
		components: {
			TiptapVuetify,
			PreciosComponent,
			SponsorsComponent
		},
		data: () => ({
			loading: false,
			saving: false,
			formValidity: false,
			rules: {
				required: value => !!value || 'Required.',
			},
			evento: {
				titulo: '',
				slug: '',
				subtitulo: '',
				informacion: '',
				fechaInicio: '',
				fechaFin:'',
				gratis: true,
				presencial: false,
				precios: [],
				afiche: '',
				programa: '',
				librito: '',
				webinar: '',
				meeting: '',
				enVivo: false,
				active: false,
				bannerDetalleHeader: {},
				bannerInscripcionHeader: {},
				bannerInscripcionEmail: {},
				bannerTransmisionHeader: {},
				bannerTransmisionHeader: {},
				bannerTransmisionFooter: {},
				sponsors: [],
			},
			extensions: [
				History,
				Blockquote,
				Link,
				Underline,
				Strike,
				Italic,
				ListItem,
				BulletList,
				OrderedList,
				Bold,
				HorizontalRule,
				Paragraph,
				HardBreak
			],
			precios: [],
			sponsors: []
		}),
		methods: {
			initEvento(){
				this.evento = {
					titulo: '',
					slug: '',
					subtitulo: ' Escriba el subtitulo del evento (Fecha, slogan)',
					informacion: ' Escriba la información importante',
					fechaInicio: '',
					fechaFin: '',
					gratis: true,
					presencial: false,
					precios: [],
					afiche: '',
					programa: '',
					librito: '',
					webinar: '',
					meeting: '',
					enVivo: false,
					active: false,
					bannerDetalleHeader: {},
					bannerInscripcionHeader: {},
					bannerInscripcionEmail: {},
					bannerTransmisionHeader: {},
					bannerTransmisionHeader: {},
					bannerTransmisionFooter: {},
					sponsors: [],
				}
			},
			setPrecios(data){
				this.precios = this.evento.precios = data;
			},
			setSponsors(data){
				this.sponsors = this.evento.sponsors = data;
			},
			async storeEvento(){
				this.loading = true
				if ( !this.evento.gratis  && this.evento.precios.length == 0 ) {
					this.$store.commit('SET_ALERT_MESSAGE', {
						show: true,
						color: 'red',
						message: 'Lo sentimos, Evento Pago sin precios! Revise',
						timeout: 4000
					}, {root: true})
					this.loading = false
					return
				}

				try {
					const resp = axios.post('evento/add', this.evento, configuration)
					this.$store.commit('SET_ALERT_MESSAGE', {
						show: true,
						color: 'success',
						message: 'Evento creado satisfactoriamente',
						timeout: 4500
					}, {root: true})
					this.goEventoList()
				} catch (e) {
					this.$store.commit('SET_ALERT_MESSAGE', {
						show: true,
						color: 'red',
						message: 'Lo sentimos, su data no pudo ser procesada! Revise',
						timeout: 4500
					}, {root: true})
				} finally {
					this.loading = false
				}
			},


			goEventoList(){
				this.initEvento()
				this.$refs.form.reset()
				this.$router.push({name: 'EventoList'})
			}
		},
		computed: {
		...mapState(['alert']),
		},
	}
</script>

<style lang="scss" scoped>

</style>